import React from "react"
import { Form } from "skillstrainer-resource-library"
import TrainingFormBuilder from "../formBuilder/training-form-builder"
import axios from "axios"
import { ToastContainer } from "react-toastify"
export default function TrainingRegistrationForm() {
  const handleSubmit = async register => {
    console.log("register===", register)
    const payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",
        coupon_code: register.coupon_code,
        data: {
          form_type: "orientation_program",
          full_name: register.full_name,
          designation: register.designation,
          email: register.email,
          remarks: register.remarks,
          notification_updates: register.notification_updates,
          registration_type: "orientation_program",
          company_name: "not required",
          phone_number: register.phone_number,
          country: "not required",
        },
      },
    }

    try {
      const result = await axios.post(`${process.env.URL}/api/orders`, payload)

      const paymentURL = new URL(
        "http://esg-payment.skillstrainer.in/?payloadBase64="
      )

      const payLOadJson = JSON.stringify(payload)
      const payloadBase64 = window.btoa(payLOadJson)

      paymentURL.searchParams.set("payloadBase64", payloadBase64)
      window.open(paymentURL.href, "_self")
    } catch (err) {
      console.log("I am error", err)
    }

    // try {
    //   const result = await axios.post(
    //     `${process.env.URL}/api/user-interests`,
    //     payload
    //   )

    //   if (result.status === 200) {
    //     return toast.success("Success", {
    //       position: toast.POSITION.TOP_CENTER,
    //     })
    //   } else {
    //     toast.error("Something gone wrong!", {
    //       position: toast.POSITION.TOP_CENTER,
    //     })
    //   }
    // } catch (err) {
    //   console.log("I am error", err)
    //   return toast.error("Something gone wrong!", {
    //     position: toast.POSITION.TOP_CENTER,
    //   })
    // }
  }
  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <ToastContainer />
        {/* <h4 class="heading-four fw-bold mt-5">
          EARLY BIRD REGISTRATION TILL 20TH JULY
          <br />
          (15% DISCOUNT)
        </h4> */}
        {/* <AwardDeadlineBanner /> */}

        <h1 className="heading mb-3">WHY YOU SHOULD ATTEND?</h1>
        <div className="row mb-5">
          <div className="col-md-6">
            <ul className="comman-para">
              <li>
                Experts in each area of ESG from research, policy formulation
                etc
              </li>
              <li>Inclusion in Indian ESG Network Alumni Database</li>
              <li>No-cost access to ESG Online Orientation Program</li>
              <li>ESG Network Access</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="comman-para">
              <li>Free Membership of Indian ESG Network</li>
              <li>
                20% fees waiver for all future Indian ESG Network Programs
              </li>
              <li>ESG Readiness Checklist</li>
            </ul>
          </div>
        </div>
        <h1 className="heading mb-3">ORIENTATION PROGRAM REGISTRATION FORM</h1>
        <h5 className="heading-four fw-bold my-3">
          REGISTRATION FEE : 6,000 PER PERSON + 18% GST
        </h5>
        <Form
          formBuilder={TrainingFormBuilder}
          className=" ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        />
      </div>

      <div className="mt-5 pt-5"></div>
    </>
  )
}

import { useState, useEffect } from "react"

function TrainingFormBuilder(values) {
  const [schema, setSchema] = useState({})

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Your Name",
        type: "text",
        required: true,
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      designation: {
        label: "Designation",
        type: "text",
        required: true,
      },
      phone_number: {
        required: true,
        label: "Phone Number",
        type: "number",
      },
      remarks: {
        label: "Any questions about the program?",
        type: "text",
      },
      notification_updates: {
        label:
          "By filling out this form, you agree to receive communication regarding future events and updates from IESGN.",
        type: "select",
        options: [
          {
            value: "Yes",
            label: "Yes",
          },
          {
            value: "No",
            label: "No",
          },
        ],
      },
      coupon_code: {
        label: "Coupon Code",
        type: "text",
      },
    }

    setSchema(schema)
  }, [values])

  return schema
}

export default TrainingFormBuilder
